<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-row>
      <v-col />
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      class="elevation-1"
      stle
      :loading-text="$t('table.messages.loading')"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 30, 50],
        showFirstLastPage: false,
        'items-per-page-text': $t('table.messages.items_per_page'),
      }"
      @click:row="clickRow"
    >
      <template slot="no-data">
        {{ $t("table.messages.no_data") }}
      </template>
      <template
        v-slot:footer.page-text=""
        class="mr-0"
      >
        {{ $t("table.messages.page") }}
        {{ options.page }}
      </template>
      <template v-slot:top>
        <v-container class="mt-5">
          <v-row>
            <v-col
              class="pb-0"
              cols="4"
            >
              <v-row>
                <v-col class="pb-0">
                  <div>
                    <span class="text-h4 font-weight-bold">{{
                      $t("messages.requestDocuments")
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <div>
                    <v-select
                      v-model="requestType"
                      :items="requestTypes"
                      item-text="name"
                      item-value="value"
                      outlined
                      :no-data-text="$t('table.messages.no_data')"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class="pb-0"
              cols="4"
            >
              <v-row>
                <v-col class="pb-0">
                  <div>
                    <span class="text-h4 font-weight-bold">{{
                      $t("messages.status")
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <div>
                    <v-select
                      v-model="statusRequest"
                      :items="statusArray"
                      item-text="name"
                      item-value="value"
                      outlined
                      :no-data-text="$t('table.messages.no_data')"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-lg-space-between">
            <v-col
              cols="2"
              class="mr-5"
            >
              <v-btn
                v-if="userType !== 1"
                color="forth"
                class="mb-5 pt-0 white--text"
                large
                block
                @click="openDialogCreate"
              >
                <span class="font-weight-bold text-h5">
                  {{ $t("messages.newRequest") }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              class="mr-5"
            >
              <v-row class="d-flex">
                <v-col cols="10">
                  <div class="align-self-center mr-9">
                    <v-text-field
                      id="search"
                      v-model="search"
                      name="search pa-0 "
                      outlined
                      :placeholder="$t('placeholder.searchDocument')"
                    />
                  </div>
                </v-col>

                <v-col
                  cols="2"
                  class="d-flex align-end p-0"
                >
                  <div class="mb-9">
                    <v-btn
                      large
                      color="primary"
                      @click="handleSearch"
                    >
                      <span class="pr-5 pl-5 font-weight-bold text-h5">
                        {{ $t("table.messages.search") }}</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.index="props">
        {{ getIndex(props.index) }}
      </template>
      <template v-slot:item.requester="props">
        {{ getRequester(getValueObject(props.item, "requestByUser")) }}
      </template>
      <template v-slot:item.requestTitle="props">
        {{ getValueObject(props.item, "approvalNumber") }}
      </template>
      <template v-slot:item.requestDocuments="props">
        {{ getValueObject(props.item, "type") }}
      </template>

      <template v-slot:item.requestDate="props">
        {{
          convertTime(
            getValueObject(props.item, "createdAt")
          )
        }}
      </template>

      <template v-slot:item.status="props">
        <span :class="getClassStatus(getValueObject(props.item, 'status'))">
          {{ getStatus(getValueObject(props.item, "status")) }}
        </span>
      </template>

      <template v-slot:item.authorizer="props">
        {{ getDesiredBy(getValueObject(props.item, "approveCertificates")) }}
      </template>

      <template v-slot:footer.prepend />
    </v-data-table>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t("messages.close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-row justify="center">
      <!-- Create Dialog -->
      <v-dialog
        v-model="dialogCreateRequest"
        max-width="750px"
      >
        <v-card>
          <validation-observer v-slot="{ handleSubmit }">
            <v-form
              ref="form"
              lazy-validation
              @submit.prevent="handleSubmit(handleCreateDocument)"
            >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col class="pb-0">
                      <v-row
                        class="d-flex justify-md-space-between align-center"
                      >
                        <v-col
                          cols="4"
                          class="pb-0"
                        >
                          <div>
                            <span class="text-h4 font-weight-bold">{{
                              $t("messages.requestDocuments")
                            }}</span>
                          </div>
                        </v-col>
                        <v-col cols="1">
                          <v-btn
                            icon
                            @click="closeCreateRequest"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="pb-0"
                          cols="6"
                        >
                          <div>
                            <v-select
                              v-model="requestCreateType"
                              :items="requestCreateTypes"
                              item-text="name"
                              item-value="value"
                              outlined
                              :no-data-text="$t('table.messages.no_data')"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="userType === 2">
                    <v-col
                      v-if="requestCreateType !== 'ringisho'"
                      class="pb-0"
                    >
                      <v-row
                        class="d-flex justify-md-space-between align-center"
                      >
                        <v-col
                          cols="4"
                          class="pb-0"
                        >
                          <div>
                            <span class="text-h4 font-weight-bold">{{
                              $t("messages.desiredApprove")
                            }}</span>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="pb-0"
                          cols="6"
                        >
                          <div>
                            <v-select
                              v-model="adminId"
                              :items="adminArray"
                              item-text="username"
                              item-value="id"
                              outlined
                              :no-data-text="$t('table.messages.no_data')"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-if="requestCreateType === 'ringisho'"
                      cols="6"
                      class="pb-0"
                    >
                      <v-row
                        class="d-flex justify-md-space-between align-center"
                      >
                        <v-col class="pb-0">
                          <div>
                            <span class="text-h4 font-weight-bold">{{
                              $t("messages.desiredApprove1")
                            }}</span>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pb-0">
                          <div>
                            <v-select
                              v-model="adminId1"
                              :items="adminArray"
                              item-text="username"
                              item-value="id"
                              :item-disabled="(item) => item.id === adminId2"
                              outlined
                              :no-data-text="$t('table.messages.no_data')"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-if="requestCreateType === 'ringisho'"
                      cols="6"
                      class="pb-0"
                    >
                      <v-row
                        class="d-flex justify-md-space-between align-center"
                      >
                        <v-col class="pb-0">
                          <div>
                            <span class="text-h4 font-weight-bold">{{
                              $t("messages.desiredApprove2")
                            }}</span>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pb-0">
                          <div>
                            <v-select
                              v-model="adminId2"
                              :items="adminArray"
                              item-text="username"
                              item-value="id"
                              :item-disabled="(item) => item.id === adminId1"
                              outlined
                              :no-data-text="$t('table.messages.no_data')"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="requestCreateType !== 'ringisho'">
                    <v-col
                      cols="2"
                      class="mt-5"
                    >
                      <div>
                        <span class="text-h4 font-weight-bold">{{
                          $t("messages.requestDocuments")
                        }}</span>
                      </div>
                    </v-col>
                    <v-col cols="7">
                      <div class="align-self-center mr-9">
                        <v-text-field
                          id="search"
                          v-model="manageId"
                          name="search pa-0 "
                          outlined
                          :placeholder="$t('placeholder.searchDocument')"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="2"
                      class="d-flex align-end p-0"
                    >
                      <div class="mb-9">
                        <v-btn
                          large
                          color="primary"
                          @click="getParttimerEmployee"
                        >
                          <span class="pr-5 pl-5 font-weight-bold text-h5">
                            {{ $t("table.messages.search") }}</span>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div>
                        <span class="text-h4 font-weight-bold">{{
                          $t("messages.content")
                        }}</span>
                      </div>
                      <v-card
                        v-if="requestCreateType === 'ringisho'"
                        outlined
                      >
                        <v-card-text>
                          <v-row class="d-flex justify-center">
                            <v-col
                              class="text-right mt-4"
                              cols="3"
                            >
                              <span
                                class="text-h4 font-weight-bold"
                              >{{ $t("messages.name") }}
                                <span class="red--text"> *</span></span>
                            </v-col>
                            <v-col cols="7">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="name"
                                  clearable
                                  outlined
                                  :error-messages="errors[0]"
                                />
                              </validation-provider>
                            </v-col>
                          </v-row>
                          <v-row class="d-flex justify-center">
                            <v-col
                              class="text-right mt-4"
                              cols="3"
                            >
                              <span
                                class="text-h4 font-weight-bold"
                              >{{ $t("messages.subject")
                              }}<span class="red--text"> *</span></span>
                            </v-col>
                            <v-col cols="7">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="subject"
                                  clearable
                                  outlined
                                  :error-messages="errors[0]"
                                />
                              </validation-provider>
                            </v-col>
                          </v-row>
                          <v-row class="d-flex justify-center">
                            <v-col
                              class="text-right mt-4"
                              cols="3"
                            >
                              <span
                                class="text-h4 font-weight-bold"
                              >{{ $t("messages.agreement")
                              }}<span class="red--text"> *</span></span>
                            </v-col>
                            <v-col cols="7">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <v-textarea
                                  v-model="contentOfDiscussion"
                                  clearable
                                  outlined
                                  :error-messages="errors[0]"
                                />
                              </validation-provider>
                            </v-col>
                          </v-row>
                          <v-row class="d-flex justify-center">
                            <v-col
                              class="text-right mt-4"
                              cols="3"
                            >
                              <span class="text-h4 font-weight-bold">{{
                                $t("messages.reasonOfApplication")
                              }}</span>
                            </v-col>
                            <v-col cols="7">
                              <v-textarea
                                v-model="reason"
                                clearable
                                outlined
                              />
                            </v-col>
                          </v-row>
                          <v-row class="d-flex justify-center">
                            <v-col
                              class="text-right mt-4"
                              cols="3"
                            >
                              <span class="text-h4 font-weight-bold">{{
                                $t("messages.findings")
                              }}</span>
                            </v-col>
                            <v-col cols="7">
                              <v-textarea
                                v-model="description"
                                clearable
                                outlined
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-card
                        v-else
                        outlined
                      >
                        <v-card-text>
                          <v-row class="d-flex justify-center">
                            <v-col class="text-right">
                              <span class="text-h4 font-weight-bold">{{
                                $t("messages.name")
                              }}</span>
                            </v-col>
                            <v-col>
                              <span
                                class="forth--text text-h4 font-weight-bold"
                              >{{ getValueObject(parttime, "name") }}</span>
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="requestCreateType === 'incumbency'"
                            class="d-flex justify-center"
                          >
                            <v-col class="text-right">
                              <span class="text-h4 font-weight-bold">{{
                                $t("messages.sex")
                              }}</span>
                            </v-col>
                            <v-col>
                              <span
                                v-if="
                                  getValueObject(parttime, 'sex', null) !== null
                                "
                                class="forth--text text-h4 font-weight-bold"
                              >
                                {{
                                  getValueObject(parttime, "sex")
                                    ? $t("messages.male")
                                    : $t("messages.female")
                                }}
                              </span>
                            </v-col>
                          </v-row>
                          <v-row class="d-flex justify-center">
                            <v-col class="text-right">
                              <span class="text-h4 font-weight-bold">{{
                                requestCreateType === "incumbency"
                                  ? $t("messages.dob")
                                  : $t("messages.retirementDate")
                              }}</span>
                            </v-col>
                            <v-col>
                              <span
                                class="forth--text text-h4 font-weight-bold"
                              >{{
                                requestCreateType === "incumbency"
                                  ? convertTime(
                                    getValueObject(parttime, "dob")
                                  )
                                  : getLastTimeWork(
                                    getValueObject(parttime, "lastWorkDate")
                                  )
                              }}</span>
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="userType === 1"
                            class="d-flex justify-center"
                          >
                            <v-col class="text-right">
                              <span class="text-h4 font-weight-bold">{{
                                $t("messages.dateOfIssue")
                              }}</span>
                            </v-col>
                            <v-col>
                              <span
                                v-if="parttime !== {}"
                                class="forth--text text-h4 font-weight-bold"
                              >
                                {{ getNowDay() }}
                              </span>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row v-if="requestCreateType === 'ringisho'">
                    <v-col>
                      <span class="text-h4 font-weight-bold">{{
                        $t("messages.signature")
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row v-if="requestCreateType === 'ringisho'">
                    <v-col
                      v-for="(item, index) in arraySign"
                      :key="`sign-${index}`"
                      cols="2"
                    >
                      <div class="text-center">
                        <span
                          v-if="arraySign.length > 1"
                        >{{ index + 1 }}人目</span>
                        <signature-pad
                          :is-border="true"
                          :item="item"
                          :index="index"
                          :src-image="item.signature"
                          :delete-sign="true"
                          @getSignatureArray="getSignatureArray"
                          @deleteSignature="deleteSignature"
                        />
                      </div>
                    </v-col>
                    <v-col
                      v-if="arraySign.length < 5"
                      cols="2"
                      class="d-flex align-center"
                    >
                      <v-btn
                        class="mx-2"
                        fab
                        small
                        dark
                        outlined
                        color="indigo"
                        @click="updateArraySign"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1 font-weight-bold"
                  large
                  dark
                  type="submit"
                >
                  {{ $t("messages.save") }}
                </v-btn>
                <v-btn
                  v-if="userType === 1"
                  :disabled="!parttime.id"
                  color="blue darken-1 font-weight-bold"
                  large
                  :dark="!!parttime.id"
                  @click="openExport"
                >
                  {{ $t("messages.saveAndExportDocument") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </validation-observer>
        </v-card>
      </v-dialog>

      <!-- Approve request -->

      <v-dialog
        v-model="dialogApproveRequest"
        max-width="750px"
      >
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col class="pb-0">
                  <v-row class="d-flex justify-md-space-between align-center">
                    <v-col
                      cols="4"
                      class="pb-0"
                    />
                    <v-col cols="1">
                      <v-btn
                        icon
                        @click="closeApprove"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-0">
                      <v-row class="d-flex justify-center">
                        <v-col class="text-right">
                          <span class="text-h4 font-weight-bold">{{
                            $t("messages.requestDocuments")
                          }}</span>
                        </v-col>
                        <v-col>
                          <span class="forth--text text-h4 font-weight-bold">{{
                            getNameRequest(requestApproveType)
                          }}</span>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="userType === 2 && requestApproveType !== 3"
                        class="d-flex justify-center"
                      >
                        <v-col class="text-right">
                          <span class="text-h4 font-weight-bold">{{
                            $t("messages.desiredApprove")
                          }}</span>
                        </v-col>
                        <v-col>
                          <span class="forth--text text-h4 font-weight-bold">{{
                            desiredByUser.username
                          }}</span>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="requestApproveType !== 3"
                        class="d-flex justify-center"
                      >
                        <v-col class="text-right">
                          <span class="text-h4 font-weight-bold">{{
                            $t("messages.manage")
                          }}</span>
                        </v-col>
                        <v-col>
                          <span class="forth--text text-h4 font-weight-bold">{{
                            getValueObject(
                              parttimeApproved,
                              "parttimer.manageId"
                            )
                          }}</span>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="requestApproveType !== 3"
                        class="d-flex justify-center"
                      >
                        <v-col class="text-right">
                          <span class="text-h4 font-weight-bold">{{
                            $t("messages.requestTitle")
                          }}</span>
                        </v-col>
                        <v-col>
                          <span class="forth--text text-h4 font-weight-bold">
                            {{
                              getValueObject(parttimeApproved, "approvalNumber")
                            }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <div>
                    <span class="text-h4 font-weight-bold">{{
                      $t("messages.content")
                    }}</span>
                  </div>
                  <v-card
                    v-if="requestApproveType === 3"
                    outlined
                  >
                    <v-card-text>
                      <v-row class="d-flex justify-center">
                        <v-col
                          class="text-right mt-4"
                          cols="3"
                        >
                          <span class="text-h4 font-weight-bold">{{
                            $t("messages.name")
                          }}</span>
                        </v-col>
                        <v-col cols="7">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              :value="getValueObject(parttimeApproved, 'name')"
                              readonly
                              outlined
                              :error-messages="errors[0]"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="isManager && requestApproveType === 3"
                        class="d-flex justify-center"
                      >
                        <v-col
                          class="text-right"
                          cols="3"
                        >
                          <span class="text-h4 font-weight-bold">{{
                            $t("messages.draftingDate")
                          }}</span>
                        </v-col>
                        <v-col cols="7">
                          <p class="font-weight-bold text-h4 approvedNumber">
                            {{ formatTime(getValueObject(parttimeApproved, "createdAt")) }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="(isManager && requestApproveType === 3) || itemApproved "
                        class="d-flex justify-center"
                      >
                        <v-col
                          class="text-right mt-4"
                          cols="3"
                        >
                          <span class="text-h4 font-weight-bold">
                            {{ $t("messages.applicableDate") }}
                            <span class="red--text"> *</span>
                          </span>
                        </v-col>
                        <v-col cols="7">
                          <v-menu
                            v-if="!itemApproved"
                            v-model="showPickerapplicableDate"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :value="applicableDate"
                                name="pa-1"
                                single-line
                                outlined
                                v-bind="attrs"
                                clearable
                                append-icon="mdi-calendar"
                                readonly
                                hint="YYYY/MM/DD"
                                v-on="on"
                                @click:clear="applicableDate = ''"
                              />
                            </template>
                            <v-date-picker
                              v-model="applicableDate"
                              :first-day-of-week="0"
                              locale="ja-jn"
                              @input="showPickerapplicableDate = false"
                            />
                          </v-menu>
                          <v-text-field
                            v-if="itemApproved"
                            :value="
                              formatTime(getValueObject(parttimeApproved, 'applicableDate'))
                            "
                            readonly
                            outlined
                          />
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <v-col
                          class="text-right"
                          cols="3"
                        >
                          <span class="text-h4 font-weight-bold">{{
                            $t("messages.approvalNumber")
                          }}</span>
                        </v-col>
                        <v-col cols="7">
                          <p class="font-weight-bold text-h4 approvedNumber">
                            {{
                              getValueObject(parttimeApproved, "approvalNumber")
                            }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <v-col
                          class="text-right mt-4"
                          cols="3"
                        >
                          <span class="text-h4 font-weight-bold">{{
                            $t("messages.subject")
                          }}</span>
                        </v-col>
                        <v-col cols="7">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              :value="
                                getValueObject(parttimeApproved, 'subject')
                              "
                              readonly
                              outlined
                              :error-messages="errors[0]"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <v-col
                          class="text-right mt-4"
                          cols="3"
                        >
                          <span class="text-h4 font-weight-bold">{{
                            $t("messages.agreement")
                          }}</span>
                        </v-col>
                        <v-col cols="7">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-textarea
                              :value="
                                getValueObject(
                                  parttimeApproved,
                                  'contentOfDiscussion'
                                )
                              "
                              no-resize
                              readonly
                              outlined
                              :error-messages="errors[0]"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <v-col
                          class="text-right mt-4"
                          cols="3"
                        >
                          <span class="text-h4 font-weight-bold">{{
                            $t("messages.reasonOfApplication")
                          }}</span>
                        </v-col>
                        <v-col cols="7">
                          <v-textarea
                            :value="getValueObject(parttimeApproved, 'reason')"
                            no-resize
                            readonly
                            outlined
                          />
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <v-col
                          class="text-right mt-4"
                          cols="3"
                        >
                          <span class="text-h4 font-weight-bold">{{
                            $t("messages.findings")
                          }}</span>
                        </v-col>
                        <v-col cols="7">
                          <v-textarea
                            :value="
                              getValueObject(parttimeApproved, 'description')
                            "
                            no-resize
                            readonly
                            outlined
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-card
                    v-else
                    outlined
                  >
                    <v-card-text>
                      <v-row class="d-flex justify-center">
                        <v-col class="text-right">
                          <span class="text-h4 font-weight-bold">{{
                            $t("messages.name")
                          }}</span>
                        </v-col>
                        <v-col>
                          <span class="forth--text text-h4 font-weight-bold">{{
                            getValueObject(parttimeApproved, "name")
                          }}</span>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="requestApproveType === 2"
                        class="d-flex justify-center"
                      >
                        <v-col class="text-right">
                          <span class="text-h4 font-weight-bold">{{
                            $t("messages.sex")
                          }}</span>
                        </v-col>
                        <v-col>
                          <span
                            v-if="
                              getValueObject(parttimeApproved, 'parttimer.sex', null) !==
                                null
                            "
                            class="forth--text text-h4 font-weight-bold"
                          >
                            {{
                              getValueObject(parttimeApproved, "parttimer.sex")
                                ? $t("messages.male")
                                : $t("messages.female")
                            }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <v-col class="text-right">
                          <span class="text-h4 font-weight-bold">{{
                            requestApproveType === 2
                              ? $t("messages.dob")
                              : $t("messages.retirementDate")
                          }}</span>
                        </v-col>
                        <v-col>
                          <span class="forth--text text-h4 font-weight-bold">{{
                            requestApproveType === 2
                              ? convertTime(
                                getValueObject(parttimeApproved, "parttimer.dob")
                              )
                              : getLastTimeWork(
                                getValueObject(
                                  parttimeApproved,
                                  "lastWorkDate"
                                )
                              )
                          }}</span>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="userType === 1"
                        class="d-flex justify-center"
                      >
                        <v-col class="text-right">
                          <span class="text-h4 font-weight-bold">{{
                            $t("messages.dateOfIssue")
                          }}</span>
                        </v-col>
                        <v-col>
                          <span class="forth--text text-h4 font-weight-bold">
                            {{
                              convertTime(
                                getValueObject(
                                  parttimeApproved,
                                  "approveCertificates[0].createdAt"
                                )
                              )
                            }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-if="requestApproveType === 3">
                <v-col>
                  <span class="text-h4 font-weight-bold">{{
                    $t("messages.signature")
                  }}</span>
                </v-col>
              </v-row>
              <v-row v-if="requestApproveType === 3">
                <v-col
                  v-for="(item, index) in parttimeApprovedArray"
                  :key="`parttimeApproved-${index}`"
                  cols="2"
                >
                  <div class="text-center">
                    <span v-if="parttimeApprovedArray.length > 1">
                      {{ index + 1 }}人目
                    </span>
                    <signature-pad
                      :is-border="true"
                      :src-image="item.signature"
                      :is-submitted="true"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="requestApproveType === 3">
                <div
                  v-if="adminApproveArray[0]"
                  class="row-custom"
                >
                  <div class="text-center">
                    <span
                      :class="{ approvedNumber: false }"
                      class="font-weight-bold text-h4"
                    >
                      役席の署名
                    </span>

                    <signature-pad
                      :is-border="true"
                      :src-image="adminApproveArray[0] && adminApproveArray[0].signature"
                      :is-submitted="
                        userType != 1 ||
                          isManager ||
                          Boolean(adminApproveArray[0].signature) ||
                          isCantSign
                      "
                      @getSignature="getSignature"
                    />
                  </div>
                </div>
                <div class="row-custom">
                  <div
                    v-if="userType !== 1 || isManager || itemApproved"
                    class="text-center"
                  >
                    <span
                      :class="{ approvedNumber: true }"
                      class="font-weight-bold text-h4"
                    >
                      役員の署名
                    </span>

                    <signature-pad
                      :is-border="true"
                      :src-image="adminApproveArray[1] && adminApproveArray[1].signature"
                      :is-submitted=" userType !== 1 || !isManager || isCantSign"
                      @getSignature="getSignature"
                    />
                  </div>
                </div>
              </v-row>
            </v-container>
          </v-card-text>
          <!-- acctions btn -->
          <v-card-actions v-if="userType === 1">
            <v-spacer />
            <span
              v-if="currentStatus === 'ADMIN_REFUSED'"
              class="text-h4 font-weight-bold mr-5"
            ><v-icon>mdi-window-close</v-icon>否認</span>
            <span
              v-if="itemApproved"
              class="text-h4 font-weight-bold mr-5"
            ><v-icon>mdi-check</v-icon>承認済み</span>
            <v-card-actions v-if="!rejectByMe">
              <v-btn
                v-if="currentStatus === 'WAITTING' && !approvedByMe"
                class="font-weight-bold"
                color="#959596"
                large
                dark
                @click="handleApprove(false)"
              >
                {{ $t("messages.refuse") }}
              </v-btn>
              <v-btn
                v-if="!isCantSign && !approvedByMe"
                color="blue darken-1 font-weight-bold"
                large
                dark
                @click="handleApprove(true)"
              >
                {{ $t("messages.approve") }}
              </v-btn>
              <v-btn
                v-if="userType === 1 && !itemApproved"
                class="font-weight-bold"
                color="blue darken-1"
                large
                dark
                @click="previewPDF()"
              >
                {{ $t("button.preview") }}
              </v-btn>
              <v-btn
                v-if="userType === 1 && itemApproved"
                class="font-weight-bold"
                color="primary"
                large
                @click="exportRequest()"
              >
                {{ $t("messages.exportCsv") }}
              </v-btn>
            </v-card-actions>
          </v-card-actions>

          <v-card-actions v-if="userType === 2">
            <v-spacer />
            <span
              v-if="currentStatus === 'ADMIN_REFUSED'"
              class="text-h4 font-weight-bold mr-5"
            ><v-icon>mdi-window-close</v-icon>否認</span>
            <span
              v-if="itemApproved"
              class="text-h4 font-weight-bold mr-5"
            ><v-icon>mdi-check</v-icon>承認済み</span>
            <v-btn
              v-if="btnRejectIsDisplay"
              class="font-weight-bold"
              color="#E43E08"
              large
              dark
              @click="dialogCancel = true"
            >
              {{ $t("messages.withdrawal") }}
            </v-btn>
            <v-btn
              v-if="itemApproved"
              class="font-weight-bold"
              color="primary"
              large
              @click="exportRequest()"
            >
              {{ $t("messages.exportCsv") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Export dialog -->
      <v-dialog
        v-model="dialogExport"
        max-width="500px"
      >
        <v-card>
          <v-card-title class="font-weight-bold text-h4">
            <v-row class="text-center">
              <v-col> 内容を保存して、エクスポートしますか。 </v-col>
            </v-row>
          </v-card-title>
          <v-card-actions>
            <v-spacer />

            <v-btn
              class="text-h5 font-weight-bold"
              @click="handlerSaveAndExport"
            >
              {{ $t("messages.export") }}
            </v-btn>

            <v-btn
              class="text-h5 font-weight-bold"
              dark
              color="#4262FF"
              @click="dialogExport = false"
            >
              {{ $t("messages.cancel") }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Cancell dialog -->

      <v-dialog
        v-model="dialogCancel"
        max-width="500px"
      >
        <v-card>
          <v-card-title class="font-weight-bold text-h4">
            <v-row class="text-center">
              <v-col>
                この操作は元に戻りません。<br>
                本当に要求を取り下げてよろしいですか？
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-actions>
            <v-spacer />

            <v-btn
              class="text-h5 font-weight-bold"
              dark
              color="#E43E08"
              @click="rejectRequest"
            >
              {{ $t("messages.withdrawal") }}
            </v-btn>
            <v-btn
              class="text-h5 font-weight-bold"
              @click="dialogCancel = false"
            >
              {{ $t("messages.close") }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import _ from 'lodash'
  import moment from 'moment'
  import { convertTime } from '../../util/covertTime'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import { email, min, required } from 'vee-validate/dist/rules'
  import SignaturePad from '../../components/SignaturePad'

  export default {
    name: 'ListDocument',
    components: {
      ValidationProvider,
      ValidationObserver,
      SignaturePad,
    },
    data () {
      return {
        moment,
        applicableDate: '',
        showPickerapplicableDate: false,
        snackbarMessage: '',
        snackbar: false,
        dialogCancel: false,
        snackbarCloseInerval: null,
        isSelectingFile: true,
        fileIsValid: true,
        selected: {},
        singleSelect: true,
        options: {},
        accounts: [],
        headers: [],
        editedIndex: -1,
        items: [],
        search: '',
        loading: false,
        isCantSign: false,
        desiredByUser: {
          username: '',
        },
        statusArray: [
          {
            name: this.$t('messages.all'),
            value: '',
          },
          {
            name: '承認待ち',
            value: 'WAITTING',
          },
          {
            name: '承認済み',
            value: 'APPROVED',
          },
          {
            name: '否認',
            value: 'ADMIN_REFUSED',
          },
          {
            name: '取り下げ',
            value: 'FULLTIME_CANCEL',
          },
          {
            name: 'キャンセル',
            value: 'CANCEL',
          },
        ],
        statusRequest: '',
        requestTypes: [
          {
            name: this.$t('messages.all'),
            value: '',
          },
          {
            name: '退職証明書',
            value: 'retirement',
          },
          {
            name: '在職証明書',
            value: 'incumbency',
          },
          {
            name: '稟議書',
            value: 'ringisho',
          },
        ],
        requestType: '',
        requestCreateTypes: [],
        requestCreateType: 'retirement',
        requestApproveType: 'retirement',
        dialogExport: false,
        dialogCreateRequest: false,
        dialogApproveRequest: false,
        userType: '',
        manageId: '',
        parttime: {},
        parttimeApproved: {},
        adminId: '',
        adminId1: '',
        adminId2: '',
        signatueQty: 1,
        adminArray: [],
        userId: '',
        subject: '',
        contentOfDiscussion: '',
        reason: '',
        opinion: '',
        description: '',
        name: '',
        arraySign: [
          {
            signature: '',
          },
        ],
        adminApproveArray: [],
        parttimeApprovedArray: [],
        adminSign: '',
        rejectByMe: false,
        approvedByMe: false,
        itemApproved: false,
        currentStatus: '',
        isManager: false,
        btnRejectIsDisplay: false,
      }
    },
    computed: {
      ...get('document', [
        'list',
        'message',
        'commitSnackBar',
        'status',
        'error',
        'parttimeEmployee',
        'admins',
        'totalItems',
        'request',
      ]),
    },
    watch: {
      commitSnackBar (value) {
        if (value !== '') {
          this.showSnackBar(value)
        }
      },
      list (value) {
        this.items = value
      },
      parttimeEmployee (value) {
        this.parttime = value
      },
      admins (value) {
        this.adminArray = value

        if (this.adminArray.length > 0) {
          this.adminId = this.adminArray[0].id
          this.adminId1 = this.adminArray[0].id
        } else {
          this.adminId = ''
          this.adminId1 = ''
        }
      },
      options: {
        handler () {
          this.initialize()
        },
        deep: true,
      },
      requestType () {
        this.initialize()
      },
      statusRequest () {
        this.initialize()
      },
      message (value) {
        switch (value) {
          case 'success':
            this.closeCreateRequest()
            this.closeApprove()
            this.initialize()
            this.showSnackBar('messages.success')
            break
          case 'successExport':
            this.$store.dispatch('document/exportRequest', {
              id: this.request.id,
            })
            break
          case 'reject':
            this.dialogCancel = false
            this.closeCreateRequest()
            this.closeApprove()
            this.initialize()
            break
          default:
            break
        }
      },
    },
    created () {
      this.$store.dispatch('user/getProfile')
      this.userType = JSON.parse(
        JSON.parse(localStorage.getItem('user')).data,
      ).type
      this.userId = JSON.parse(JSON.parse(localStorage.getItem('user')).data).id
    },
    mounted () {
      this.$store.dispatch('document/getAdmins')
      // Define the rule globally
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })

      extend('email', {
        ...email,
        message: this.$t('validator.email_invalid'),
      })

      extend('number', {
        validate (value) {
          return /^\+?(0|[1-9]\d*)$/.test(value)
        },
        message: this.$t('validator.must_be_number'),
      })
      extend('min', {
        ...min,
        message: this.$t('validator.password_invalid'),
      })
      extend('date', {
        validate (value) {
          return moment(value, 'YYYY-mm-DD').format('YYYY-mm-DD') === value
        },
        message: this.$t('validator.invalid_date'),
      })
      extend('phone', {
        validate (value) {
          return /^[+]?[(]?[0-9]{4}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,5}$/.test(
            value,
          )
        },
        message: this.$t('数字のみ入力してください。'),
      })

      this.headers = [
        {
          text: this.$t('table.headers.index'),
          align: 'start',
          value: 'index',
          class: 'font-weight-bold',
          width: '6rem',
          sortable: false,
        },
        {
          text: this.$t('table.headers.requester'),
          align: 'start',
          value: 'requester',
          class: 'font-weight-bold',
          width: '10rem',
          sortable: false,
        },
        {
          // need to confirm
          text: this.$t('table.headers.requestTitle'),
          align: 'start',
          value: 'requestTitle',
          class: 'font-weight-bold',
          width: '8rem',
          sortable: false,
        },
        {
          text: this.$t('table.headers.requestDocuments'),
          align: 'start',
          value: 'requestDocuments',
          class: 'font-weight-bold',
          width: '8rem',
          sortable: false,
        },
        {
          text: this.$t('table.headers.requestDate'),
          align: 'start',
          value: 'requestDate',
          class: 'font-weight-bold',
          width: '8rem',
          sortable: false,
        },
        {
          text: this.$t('table.headers.status'),
          align: 'start',
          value: 'status',
          class: 'font-weight-bold',
          width: '11rem',
          sortable: false,
        },
        {
          text: this.$t('table.headers.authorizer'),
          align: 'start',
          value: 'authorizer',
          class: 'font-weight-bold',
          width: '8rem',
          sortable: false,
        },
      ]
      this.initialize()
    },
    methods: {
      convertTime,
      rejectRequest () {
        const id = this.getValueObject(this.parttimeApproved, 'id')
        if (id) {
          this.$store.dispatch('document/employeeReject', { id })
        }
      },
      getSignature (data) {
        this.adminSign = data
      },
      getSignatureArray (payload) {
        if (payload.data) {
          payload.item.signature = payload.data
        } else {
          payload.item.signature = ''
        }
      },
      deleteSignature (index) {
        this.arraySign.splice(index, 1)
      },
      updateArraySign () {
        if (this.arraySign.length > 5) return
        this.arraySign.push({
          signature: '',
        })
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      clickRow (item) {
        this.adminApproveArray = []
        this.parttimeApprovedArray = []
        this.isManager = false
        this.dialogApproveRequest = true
        this.parttimeApproved = item
        this.currentStatus = item.status
        this.isExport = item.isExport
        this.requestApproveType = item.typeId
        this.rejectByMe = false
        this.approvedByMe = false
        this.signByAdmin = false
        const statusArr = []

        item.approveCertificates.forEach((it) => {
          statusArr.push(it.status)

          if (it.typeApprove === 'admin') {
            const { isManager, desiredBy, status, signature, approveBy } = it
            const desiredByMe = desiredBy === this.userId
            if (desiredBy) {
              this.desiredByUser = it.desiredByUser
            }

            if (isManager && desiredByMe) {
              this.isManager = true
            }

            if (it.status === 'APPROVED' && approveBy === this.userId) {
              this.approvedByMe = true
            }

            if (status === 'ADMIN_REFUSED' && desiredByMe) { this.rejectByMe = true }

            this.adminApproveArray[isManager ? 1 : 0] = it

            if (this.currentStatus === 'WAITTING') {
              const isSignature = Boolean(it.signature)
              this.btnRejectIsDisplay = !isSignature
            }

            if (this.currentStatus === 'FULLTIME_CANCEL') {
              this.btnRejectIsDisplay = false
            }
            this.signByAdmin = signature !== null
          }

          if (it.typeApprove === 'employee') {
            this.isCreateByEmployee = true
            this.parttimeApprovedArray.push(it)
          }
        })
        this.itemApproved = this.currentStatus === 'APPROVED'
        this.isCantSign =
          statusArr.every((e) => e === 'APPROVED') ||
          statusArr.some((e) => e === 'ADMIN_REFUSED')
      },
      getClassStatus (status) {
        switch (status) {
          case 'WAITTING':
            return 'waitting'
          case 'ADMIN_REFUSED':
            return 'adminRefused'
          case 'APPROVED':
          case 'FULLTIME_CANCEL':
          case 'CANCEL':
          default:
            return ''
        }
      },
      getStatus (status) {
        switch (status) {
          case 'APPROVED':
            return '承認済み'
          case 'WAITTING':
            return '承認待ち'
          case 'ADMIN_REFUSED':
            return '否認'
          case 'FULLTIME_CANCEL':
            return '取り下げ'
          case 'CANCEL':
            return 'キャンセル'
          default:
            return ''
        }
      },
      formatTime (time, format = 'YYYY/MM/DD') {
        const _time = moment(time)
        if (!_time.isValid()) return undefined
        return _time.format(format)
      },
      resetForm () {
        this.signatueQty = 1
        this.requestCreateType = 'retirement'
        this.search = ''
        this.arraySign = []
        // this.adminApproveArray = []
        // this.parttimeApprovedArray = []
        this.subject = ''
        this.contentOfDiscussion = ''
        this.reason = ''
        this.opinion = ''
        this.description = ''
        this.name = ''
        this.$store.dispatch('document/updateParttimeEmployee', {
          parttimeEmployee: {},
        })
        this.requestCreateTypes = [
          {
            name: '退職証明書',
            value: 'retirement',
          },
          {
            name: '在職証明書',
            value: 'incumbency',
          },
        ]
        if (this.userType === 2) {
          this.requestCreateTypes.push({
            name: '稟議書',
            value: 'ringisho',
          })
        }
      },
      convertTimeLocate (string) {
        return string ? moment(string).format('YYYY/MM/DD') : ''
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
      getLastTimeWork (string) {
        return string ? moment(string).format('YYYY/MM/DD') : ''
      },
      openExport () {
        this.dialogExport = true
      },
      handlerSaveAndExport () {
        let adminArray = []
        const lastWorkDate = this.getValueObject(this.parttime, 'lastWorkDate')
        const payload = {
          // name: this.parttime.name,
          type: this.requestCreateType,
          parttimeEmployee: this.getValueObject(this.parttime, 'id'),
        }

        switch (this.requestCreateType) {
          case 'retirement':
            if (this.userType === 1) {
              payload.time = lastWorkDate
            }
            break
          case 'incumbency':
            if (this.userType === 1) {
              payload.time = lastWorkDate
            }
            break
          case 'ringisho':
            payload.name = this.name
            payload.subject = this.subject
            payload.contentOfDiscussion = this.contentOfDiscussion
            payload.reason = this.reason
            payload.opinion = this.opinion
            payload.description = this.description
            payload.mailNoitification = this.mailNoitification
            break
        }

        if (this.userType === 1) {
          this.$store.dispatch('document/createAndExport', payload).then(() => {
            this.dialogExport = false
          })
        }

        if (this.userType === 2) {
          const arratRingisho = []

          if (this.adminId1) {
            arratRingisho.push(this.adminId1)
          }

          if (this.adminId2) {
            arratRingisho.push(this.adminId2)
          }

          adminArray =
            this.requestCreateType === 'ringisho'
              ? arratRingisho
              : [this.adminId]
          payload.desiredBys = adminArray
          payload.numberEmployee = this.signatueQty

          this.$store.dispatch('document/createAndExport', payload).then(() => {
            this.dialogExport = false
          })
        }
      },
      getNowDay () {
        return moment(new Date()).format('YYYY/MM/DD')
      },
      getValueObject (item, path, d = '') {
        return _.get(item, path, d)
      },
      getDesiredBy (arr) {
        const temp = []
        for (const item of arr) {
          if (item.typeApprove === 'admin') temp.push(item)
        }
        return temp.reduce((pre, obj) => {
          if (obj.desiredByUser && obj.desiredByUser.username) {
            pre.push(obj.desiredByUser.username)
          }
          return pre
        }, []).join(', ')
      },
      getRequester (requestByUser) {
        if (requestByUser) {
          return `${requestByUser.name}`
        }
      },
      initialize (search = '') {
        const tmpOptions = { ...this.options }
        tmpOptions.limit = tmpOptions.itemsPerPage

        if (search) {
          tmpOptions.keyword = search
        }

        if (this.requestType) {
          tmpOptions.type = this.requestType
        }

        if (this.statusRequest) {
          tmpOptions.status = this.statusRequest
        }

        delete tmpOptions.groupDesc
        delete tmpOptions.groupBy
        delete tmpOptions.mustSort
        delete tmpOptions.multiSort
        delete tmpOptions.itemsPerPage
        delete tmpOptions.sortDesc
        delete tmpOptions.sortBy
        this.currentOptions = Object.assign({}, tmpOptions)
        this.$store.dispatch('document/getDocument', tmpOptions)
      },
      async handleCreateDocument () {
        let adminArray = []
        const lastWorkDate =
          this.getValueObject(this.parttime, 'lastWorkDate') || new Date()
        const payload = {
          // name: this.parttime.name,
          type: this.requestCreateType,
        }

        switch (this.requestCreateType) {
          case 'retirement':
            if (this.userType === 1) {
              payload.time = lastWorkDate
            }
            payload.parttimeEmployee = this.getValueObject(this.parttime, 'id')

            break
          case 'incumbency':
            if (this.userType === 1) {
              payload.time = lastWorkDate
            }
            payload.parttimeEmployee = this.getValueObject(this.parttime, 'id')

            break
          case 'ringisho':
            if (this.checkNullSign()) {
              this.showSnackBar('error.no_sign')
              return
            }
            payload.name = this.name
            payload.subject = this.subject
            payload.contentOfDiscussion = this.contentOfDiscussion
            payload.reason = this.reason
            payload.opinion = this.opinion
            payload.description = this.description
            payload.mailNoitification = this.mailNoitification
            payload.employeeSignatures = []
            this.arraySign.forEach((item) => {
              payload.employeeSignatures.push(item.signature)
            })
            break
        }

        if (this.userType === 1) {
          await this.$store.dispatch('document/adminCreate', payload)
        }

        if (this.userType === 2) {
          const arratRingisho = []

          if (this.adminId1) {
            arratRingisho.push(this.adminId1)
          }

          if (this.adminId2) {
            arratRingisho.push(this.adminId2)
          }

          adminArray =
            this.requestCreateType === 'ringisho'
              ? arratRingisho
              : [this.adminId]
          payload.desiredBys = adminArray

          await this.$store.dispatch('document/create', payload)
        }
      },
      checkNullSign () {
        return (
          this.arraySign.find((item) => item.signature === '') ||
          !this.arraySign.length
        )
      },
      async handleApprove (approve) {
        //
        let applicableDate = this.applicableDate
        if (applicableDate === '') {
          applicableDate = moment(new Date()).format('YYYY/MM/DD')
        }
        const payload = {
          approve: approve,
          id: this.parttimeApproved.id,
          applicableDate,
          signature: this.adminSign,
        }
        await this.$store.dispatch('document/approvedRequest', payload)
      },
      handleSearch () {
        this.initialize(this.search)
      },
      openDialogCreate () {
        this.resetForm()
        this.dialogCreateRequest = true
        this.manageId = ''
        this.parttime = {}
      },
      getParttimerEmployee () {
        const payload = {
          id: this.manageId,
        }
        this.$store.dispatch('document/getParttimerEmployee', payload)
      },
      closeCreateRequest () {
        this.$store.dispatch('document/updateParttimeEmployee', {
          parttimeEmployee: {},
        })
        this.dialogCreateRequest = false
        this.arraySign = []
      },
      closeApprove () {
        this.parttimeApproved = {}
        this.dialogApproveRequest = false
      },
      exportRequest () {
        const payload = {
          id: this.parttimeApproved.id,
        }
        this.$store.dispatch('document/exportRequest', payload)
      },
      previewPDF () {
        const payload = {
          id: this.parttimeApproved.id,
        }
        this.$store.dispatch('document/previewPDF', payload)
      },
      getNameRequest (type) {
        switch (type) {
          case 1:
            return '退職証明書'
          case 2:
            return '在職証明書'
          case 3:
            return '稟議書'
        }
      },
    },
  }
</script>

<style scoped lang="css">
.waitting {
  color: #4262ff;
}

.adminRefused {
  color: #e43e08;
}

.approvedNumber {
  color: #4262ff;
}
.row-custom {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
  padding: 12px;
  width: 100%;
}
</style>
